import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { getPageProps } from 'holded/app/cms/page';
import { PageLayout } from 'holded/components/Layout/PageLayout';
import { useLandingContext } from 'holded/lib/context/landingContext';
import { GlobalData, LanguageCode, Page } from 'holded/modules/cms/domain/page';
import { application } from 'holded/modules/cms/ui';
import Spinner from 'holded/modules/cms/ui/shared/components/Spinner';
import GoogleMarkup, { MarkupType } from 'holded/shared/ui/components/Markups/GoogleMarkup';

type StaticPropsType = {
  params: {
    slug: [string];
  };
  locale: LanguageCode;
  locales: [LanguageCode];
  defaultLocale: LanguageCode;
  preview: boolean;
};

type DynamicPageProps = {
  pageData: Page;
  globalData: GlobalData;
  currentLocale: LanguageCode;
  defaultLocale: LanguageCode;
};

const MainSection = dynamic(() => import('holded/components/Layout/MainSection'), {
  loading: () => <Spinner />,
});

const DynamicPage = ({ pageData, globalData, currentLocale, defaultLocale }: DynamicPageProps) => {
  const { updatePageData, updateGlobalData, updateCurrentLocale, updateDefaultLocale } = useLandingContext();
  const { boot, shutdown } = useIntercom();
  const showIntercom = pageData?.attributes?.options ? pageData.attributes.options.intercom : true;

  useEffect(() => {
    const MarkupType = ['FAQPage', 'SoftwareApplication', 'Organization', 'Article'];
    if (
      pageData?.attributes?.markup?.data?.attributes?.type &&
      MarkupType.includes(pageData.attributes.markup.data.attributes.type ?? '')
    ) {
      GoogleMarkup({
        type: pageData.attributes.markup.data.attributes.type as MarkupType,
        pageData: pageData,
        globalData: globalData,
      });
    }

    updatePageData(pageData);
    updateGlobalData(globalData);
    updateCurrentLocale(currentLocale);
    updateDefaultLocale(defaultLocale);
  }, []);

  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (process.env.NEXT_PUBLIC_INTERCOM_APP_ID && showIntercom) {
      boot({
        hideDefaultLauncher: isMobile,
      });
    }

    return () => {
      if (process.env.NEXT_PUBLIC_INTERCOM_APP_ID && showIntercom) {
        shutdown();
      }
    };
  }, [boot, shutdown]);

  return (
    <PageLayout globalData={globalData} pageData={pageData}>
      <MainSection pageData={pageData} globalData={globalData} />
    </PageLayout>
  );
};

export async function getStaticPaths() {
  const pagePaths = await application.getPagesSlugs;
  const integrationPaths = await application.getIntegrationsSlugs;
  const successCasesPaths = await application.getSuccessCasesSlugs;
  const webinarsPaths = await application.getWebinarsSlugs;
  const guideCollectionPaths = await application.getGuideCollectionSlugs;
  const guidesPaths = await application.getGuideSlugs;

  const paths =
    process.env.NEXT_PARTNERS_DIRECTORY_BUILD_ONLY === 'true'
      ? [{ params: { slug: false } }]
      : [
          ...pagePaths,
          ...integrationPaths,
          ...successCasesPaths,
          ...webinarsPaths,
          ...guideCollectionPaths,
          ...guidesPaths,
        ];

  return {
    paths,
    fallback: process.env.LIVE_RELOAD == 'true' ? 'blocking' : false,
  };
}

export const getStaticProps = async (context: StaticPropsType) => {
  const { params } = context;

  return getPageProps(params);
};

export default DynamicPage;
