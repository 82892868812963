import { Faq2Section, FaqSection } from 'holded/modules/cms/domain/components/faq';
import { Page, GlobalData } from 'holded/modules/cms/domain/page';

export type MarkupType = 'FAQPage' | 'SoftwareApplication' | 'Organization' | 'Article';

export type ArticleType = {
  slug?: string;
  headline?: string;
  images?: [string];
  published?: string;
  modified?: string;
  author?: string;
};

export type MarkupTypes = {
  FAQPage?: FAQPageMarkupType;
  SoftwareApplication?: SoftwareApplicationsMarkupType;
  Organization: OrganizationMarkupType | undefined;
  Article?: ArticleMarkupType;
};

export type ArticleMarkupType = {
  mainEntityOfPage: {
    '@type': string;
    '@id'?: string;
  };
  headline?: string;
  image?: string[];
  datePublished?: string;
  dateModified?: string;
  author: {
    '@type': string;
    name?: string;
  };
  publisher: {
    '@type'?: string;
    name?: string;
    logo?: {
      '@type': string;
      url: string;
    };
  };
};

export type SoftwareApplicationsMarkupType = {
  name?: string;
  operatingSystem: string;
  applicationCategory: string;
  aggregateRating: {
    '@type': string;
    ratingValue: string;
    ratingCount: string;
  };
  offers: {
    '@type': string;
    price: string;
  };
};

export type OrganizationMarkupType = {
  name: string;
  legalName: string;
  url: string;
  logo: string;
  foundingDate: string;
  founders: {
    '@type': string;
    name: string;
  }[];
  address: {
    '@type': string;
    streetAddress: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    addressCountry: string;
  };
  contactPoint: {
    '@type': string;
    contactType: string;
    telephone: string;
    email: string;
  };
  sameAs: string[];
};

export type FAQPageMarkupType = {
  mainEntity?: {
    '@type': string;
    name: string;
    acceptedAnswer: {
      '@type': string;
      text: string;
    };
  }[];
};

type Props = {
  type: MarkupType;
  pageData?: Page;
  faqData?: FaqSection | Faq2Section;
  article?: ArticleType;
  globalData: GlobalData;
};

function hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
  // @ts-ignore
  return key in obj;
}

const FAQMarkup = (faqData?: Props['faqData']) => {
  if (!faqData) return;
  const entity = faqData?.faqs?.map((faq) => {
    return {
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer.replace(/<[^>]+>/g, ''),
      },
    };
  });

  const markup: FAQPageMarkupType = { mainEntity: entity };

  return markup;
};

const softwareMarkup = (global: GlobalData, pageData?: Page): SoftwareApplicationsMarkupType => {
  return {
    name: pageData?.attributes?.shortName ?? '',
    operatingSystem: 'All',
    applicationCategory: 'Software',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: global.global?.attributes?.rating?.value ?? '',
      ratingCount: global.global?.attributes?.rating?.count ?? '',
    },
    offers: {
      '@type': 'Offer',
      price: '0',
    },
  };
};

const organizationMarkup = (global: GlobalData): OrganizationMarkupType | undefined => {
  const organization = global?.global?.attributes?.organization;
  const logo = global.header?.attributes?.logo?.data?.attributes?.url ?? '';
  const founders = organization?.founders.map((founder) => {
    return {
      '@type': 'Person',
      name: founder.name ?? '',
    };
  });

  const socialMedia = organization?.socialMedia?.map((social) => social.url);

  if (organization) {
    return {
      name: organization?.name,
      legalName: organization?.legalName,
      url: organization?.website,
      logo: logo,
      foundingDate: organization?.foundingDate,
      founders: founders ?? [
        {
          '@type': 'Person',
          name: '',
        },
      ],
      address: {
        '@type': 'PostalAddress',
        streetAddress: organization.addressData.address,
        addressLocality: organization.addressData.locality,
        addressRegion: organization.addressData.region,
        postalCode: organization.addressData.postalCode,
        addressCountry: organization.addressData.country,
      },
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: organization.contact.type,
        telephone: organization.contact.phone,
        email: organization.contact.email,
      },
      sameAs: socialMedia ?? [''],
    };
  }
};

const articleMarkup = (global: GlobalData, article?: ArticleType): ArticleMarkupType => {
  return {
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': article?.slug,
    },
    headline: article?.headline,
    image: article?.images,
    datePublished: article?.published,
    dateModified: article?.modified,
    author: {
      '@type': 'Person',
      name: article?.author,
    },
    publisher: {
      '@type': 'Organization',
      name: global.global?.attributes?.organization?.name,
      logo: {
        '@type': 'ImageObject',
        url: global.header?.attributes?.logo?.data?.attributes?.url ?? '',
      },
    },
  };
};

const GoogleMarkup = ({ type, pageData, faqData, article, globalData }: Props) => {
  const options = ['FAQPage', 'SoftwareApplication', 'Organization', 'Article'];
  if (!options.includes(type)) return;
  if (!type) return;

  const initialContent = { '@context': 'https://schema.org/', '@type': type };

  const types: MarkupTypes = {
    FAQPage: FAQMarkup(faqData),
    SoftwareApplication: softwareMarkup(globalData, pageData),
    Organization: organizationMarkup(globalData),
    Article: articleMarkup(globalData, article),
  };

  if (hasKey(types, type)) {
    const content = { ...initialContent, ...types[type] };

    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = JSON.stringify(content);
    document.head.appendChild(script);
  }
};

export default GoogleMarkup;
