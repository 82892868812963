import dynamic from 'next/dynamic';
import React, { FC, PropsWithChildren } from 'react';

import TopBar from 'holded/components/Layout/TopBar';
import { GlobalData, Metadata, Page } from 'holded/modules/cms/domain/page';
import Spinner from 'holded/modules/cms/ui/shared/components/Spinner';

type PageLayoutProps = {
  globalData: GlobalData;
  pageData: Page;
  metadata?: Metadata;
};

const Seo = dynamic(() => import('holded/modules/cms/ui/shared/components/Seo'));
const Header = dynamic(() => import('holded/components/Layout/Header'), { loading: () => <Spinner /> });
const Footer = dynamic(() => import('holded/components/Layout/Footer'), { loading: () => <Spinner /> });

const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({ children, globalData, pageData, metadata }) => (
  <>
    {pageData && globalData && !pageData.isDetailPage && (
      <Seo pageData={pageData} metadata={metadata} globalData={globalData} />
    )}
    <main className={`min-h-screen`}>
      {globalData?.header && pageData?.attributes?.slug !== 'error' && (
        <>
          <TopBar header={globalData.header} pageData={pageData} />
          <Header header={globalData.header} pageData={pageData} />
        </>
      )}
      {children}
    </main>
    {globalData?.footer && pageData?.attributes?.slug !== 'error' && (
      <Footer footer={globalData.footer} pageData={pageData} />
    )}
  </>
);

export type { PageLayoutProps };
export { PageLayout };
